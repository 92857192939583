/**
* Created by tangdq on 2018/8/2.
*/
<style lang="scss" scoped>
.new-add-mechanism {
    /*min-width: 1180px;*/
    .box-card {
        margin-bottom: 10px;

        .add-label {
            font-size: 14px;
            color: #333333;
            letter-spacing: 0;
            font-weight: 700;
            border-left: 2px solid #319BF7;
            height: 14px;
            line-height: 14px;
            padding-left: 8px;
        }

        .companyPC {
            width: 380px;
            text-align: left;
            color: #c0c4cc;
            font-weight: normal;
        }
    }

    .new-add-user {
        .el-button {
            float: left;
        }

        .org-btn {
            height: 30px;
            line-height: 30px;
            width: 88px;
            border: 1px solid #DCDFE6;
            text-align: center;
            border-radius: 4px;
            cursor: pointer;
            float: left;
        }
    }

    .new-add-type {
        margin-left: 12px;
        display: inline;
        float: left;
    }

    .color319 {
        background: #319BF7;
        color: #fff;
        border: 1px solid #319BF7;
    }

    .color333 {
        color: #333333 !important;
    }

    .companyPC.color333 {
        color: #333333 !important;
    }
}
</style>
<style lang="scss">
.table-img-box-upload {
    .el-button {
        padding: 0 15px;
        height: 26px;
        line-height: 26px;
        margin-left: 10px;
    }

    .icon {
        font-size: 12px;
        margin-right: 5px;
    }
}

.distpicker-address-wrapper {
    max-width: 400px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #eee;

    .address-header {
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid #eee;

        li {
            float: left;
            /*padding-right: 20px;*/
            width: 50%;
            text-align: center;
            cursor: pointer;
        }
    }

    .address-container {
        height: 300px;
        overflow-y: auto;

        li {
            cursor: pointer;
        }
    }
}

.new-add-mechanism {
    .el-form-item__label {
        line-height: 32px;
    }

    .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-form-item--small.screen-checked-com {
        /*margin-bottom: 0;*/
    }

}

.new-add-mechanism {
    .el-card {
        &.is-always-shadow {
            box-shadow: none;

        }

        border: 0;
        border-radius: 0;
    }

    .screen-checked-com {
        .checked-com-all.el-checkbox {
            display: inline;
        }

        .checked-com-group.el-checkbox-group {
            display: inline;
            margin-left: 10px;
        }

        .el-checkbox {
            .el-checkbox__label {
                padding-left: 5px;
            }
        }

        .checked-com-group {
            .el-checkbox + .el-checkbox {
                margin-left: 15px;
            }
        }
    }
}

.preview_img {
    min-height: 180px;
}

.preview_img, .see-big {
    width: 250px;
    text-align: center;

    img {
        width: 100%;
    }
}

.see-big {
    text-align: center;
    line-height: 20px;
    width: auto;
    color: #0452DC;
}

.item-w-5 {
    width: 500px;
}

.item-mr10 {
    margin-right: 10px;
}

.dialog-look-picture {
    overflow: hidden;

    .dialog-look-picture-custom {
        margin-top: 10px !important;
        height: calc(100% - 20px);
        overflow: hidden;
        min-width: 900px;
    }

    .el-dialog__body {
        padding: 0;
        text-align: center;
        height: calc(100% - 80px);
        //position: relative;
        overflow-y: scroll;

        img {
            width: auto;
            max-width: 100%;
        }

        //overflow: hidden;
        //overflow-x: scroll;
    }

    .original-image-btn {
        position: absolute;
        left: 10px;
        top: 0;

        a {
            color: #319bf7;
        }

    }
}
</style>

<template>
    <div>
        <el-main class="com-eTrip-section" :class="{'section-has-footer':showInput}">
            <div class="eTrip-section-cont new-add-mechanism">
                <el-form :model="form" :rules="ruleForm" ref="rule" label-position="right"
                         label-width="150px" size="small">
                    <el-card class="box-card">
                        <p class="add-label" style="margin-bottom: 5px">状态信息</p>
                        <el-form-item label="机构ID：" label-width="140px" label-position="right"
                                      style="margin-bottom: 0">
                            <p style="color: #999;line-height: 32px">{{ form.orgId || '保存或提交后可见' }}</p>
                            <div>
                                <el-button :disabled="!form.firstMerchantValidate"
                                           :type="form.firstMerchantValidate ==1?'primary':'info'" plain size="small">
                                    一级用户：{{ form.firstMerchantValidate == 1 ? '已开通' : '未开通' }}
                                </el-button>
                                <el-button :disabled="!form.secondMerchantValidate"
                                           :type="form.secondMerchantValidate ==1?'primary':'info'" plain size="small">
                                    二级用户：{{ form.secondMerchantValidate == 1 ? '已开通' : '未开通' }}
                                </el-button>
                                <el-button :disabled="!form.supplierValidate"
                                           :type="form.supplierValidate ==1?'primary':'info'" plain size="small">
                                    供应商：{{ form.supplierValidate == 1 ? '已开通' : '未开通' }}
                                </el-button>
                                <el-button :disabled="!form.operatorValidate"
                                           :type="form.operatorValidate ==1?'primary':'info'" plain size="small">
                                    运营商：{{ form.operatorValidate == 1 ? '已开通' : '未开通' }}
                                </el-button>
                            </div>
                        </el-form-item>
                    </el-card>
                    <el-card class="box-card">
                        <p class="add-label" style="margin-bottom: 15px">用户划分</p>
                        <el-form-item label="用户类型：" :rules="[{required:true}]" class="new-add-user clear">
                            <div @click="selectSupplier" :class="{'color319': form.supplierSelect}"
                                 class="org-btn" style="margin-right: 10px">
                                供应商
                            </div>
                            <div @click="selectOperator" style="margin-right: 10px"
                                 v-if="userInfo.loginuserDto&&userInfo.loginuserDto.mobile=='13816382127'"
                                 :class="{'color319': form.operatorSelect}" class="org-btn">运营商
                            </div>
                            <div @click="selectFirstName" :class="{'color319': form.firstSelect}"
                                 class="org-btn" style="border-radius: 4px 0 0 4px;margin-right: -1px">一级用户
                            </div>
                            <div @click="selectSecName" :class="{'color319': form.secondSelect}"
                                 class="org-btn" style="border-radius: 0 4px 4px 0">二级用户
                            </div>

                        </el-form-item>
                        <el-form-item label="所属一级用户名称：" v-if="form.secondSelect" prop="firstName">
                            <div>
                                <el-select v-model="form.firstName" :disabled="!showButton"
                                           filterable clearable
                                           no-match-text="该一级用户不存在"
                                           placeholder="请选择" style="width: 380px">
                                    <el-option
                                        v-for="item in firstList"
                                        :key="item.index"
                                        :label="item.merchantName"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </el-form-item>
                        <el-form-item label="所属运营商名称：" v-if="form.supplierSelect" prop="operatorName">
                            <el-select v-model="form.operatorName" :disabled="!showButton"
                                       filterable clearable
                                       no-match-text="该运营商不存在"
                                       placeholder="请选择" style="width: 380px">
                                <el-option
                                    v-for="item in operatorList"
                                    :key="item.index"
                                    :label="item.operatorName"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="用户名称：" prop="userName" v-if="showInput">
                            <div style="width: 380px">
                                <el-input v-model="form.userName" maxlength="50" :disabled="!showButton"
                                          placeholder="汉字/字母/数字，不超过50个字"></el-input>
                            </div>
                        </el-form-item>
                    </el-card>
                    <div v-if="showInput">
                        <el-card class="box-card">
                            <p class="add-label">机构信息</p>
                            <el-form-item label="公司名称：" prop="companyName">
                                <div style="width: 380px">
                                    <el-input placeholder="同营业执照上的公司名称" :disabled="!showButton"
                                              maxlength="100"
                                              v-model="form.companyName"></el-input>
                                </div>
                            </el-form-item>
                            <el-form-item label="营业执照号码：" prop="businessLicenseNumber"
                                          v-if="form.supplierSelect||form.operatorSelect||form.firstSelect">
                                <div style="width: 380px">
                                    <el-input placeholder="18位字母/数字" :disabled="!showButton" maxlength="18"
                                              v-model="form.businessLicenseNumber"></el-input>
                                </div>
                            </el-form-item>
                            <el-form-item label="公司所在地：" prop="companyPAndC">
                                <cascader-city v-model="form.companyPAndC" :disabled="!showButton"
                                               containerStyle="width: 380px">
                                </cascader-city>
                            </el-form-item>
                            <el-form-item prop="companyAddress">
                                <div style="width: 380px">
                                    <el-input placeholder="详细地址，不超过100字" maxlength="100" :disabled="!showButton"
                                              v-model="form.companyAddress"></el-input>
                                </div>
                            </el-form-item>
                            <el-form-item label="机构类型：" prop="orgType" class="screen-checked-com">
                                <el-checkbox :indeterminate="isIndeterminate" v-model="checkOrgTypeAll"
                                             class="checked-com-all"
                                             :disabled="!showButton"
                                             @change="handleCheckAllChange">全部
                                </el-checkbox>
                                <el-checkbox-group v-model="form.orgType" @change="getOrgType" :disabled="!showButton"
                                                   class="checked-com-group">
                                    <el-checkbox v-for="(item,index) in agencyTypeList" :label="(index+1)" :key="index">
                                        {{ item }}
                                        <el-input v-if="index==9" placeholder="请填写公司类型，如：企事业单位、会展公司等"
                                                  style="width: 330px" :disabled="!showButton"
                                                  v-model="form.otherCompanyName">
                                        </el-input>
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="经营范围：" prop="businenssScope" class="screen-checked-com">
                                <el-checkbox :indeterminate="isIndeterminate1" v-model="checkScopeAll"
                                             class="checked-com-all"
                                             :disabled="!showButton"
                                             @change="handleCheckAllChange1">全部
                                </el-checkbox>
                                <el-checkbox-group v-model="form.businenssScope" @change="getBusinessScope"
                                                   class="checked-com-group"
                                                   :disabled="!showButton">
                                    <el-checkbox v-for="(item,index) in businenssScopeList" :label="(index+1)"
                                                 :key="index">
                                        {{ item }}
                                        <el-input v-if="index==8" placeholder="选项中不包含的经营范围"
                                                  :disabled="!showButton"
                                                  v-model="form.otherBusinenss">
                                        </el-input>
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="服务城市：" prop="serverCity">
                                <div style="width: 380px">
                                    <el-select :disabled="!showButton"
                                               v-model="form.serverCity"
                                               multiple filterable
                                               placeholder="请选择服务城市">
                                        <el-option
                                            v-for="(item,index) in serverCityList"
                                            :key="index"
                                            :label="item.name"
                                            :value="item.shortCode">
                                        </el-option>
                                    </el-select>
                                </div>
                            </el-form-item>
                        </el-card>
                        <el-card class="box-card">
                            <p class="add-label">联系人信息</p>
                            <el-row>
                                <el-col v-if="form.supplierSelect||form.operatorSelect||form.firstSelect">
                                    <el-form-item label="机构法人：" style="width: 320px;float: left">
                                        <div>
                                            <el-input placeholder="姓名" :disabled="!showButton" maxlength="30"
                                                      v-model="form.legalPersonName"></el-input>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label-width="10px" style="float: left">
                                        <div style="width: 180px">
                                            <el-input placeholder="手机号" :disabled="!showButton" maxlength="11"
                                                      v-model="form.legalPersonPhone"></el-input>
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col v-if="form.supplierSelect||form.operatorSelect||form.firstSelect">
                                    <el-form-item style="float: left;width: 320px">
                                        <div>
                                            <el-select v-model="form.idType" :disabled="!showButton"
                                                       placeholder="证件类型">
                                                <el-option label="身份证" value="1"></el-option>
                                                <el-option label="护照" value="2"></el-option>
                                            </el-select>
                                        </div>

                                    </el-form-item>
                                    <el-form-item label-width="10px" style="float: left">
                                        <div style="width: 180px">
                                            <el-input placeholder="证件号码" :disabled="!showButton" maxlength="30"
                                                      v-model="form.idNo"></el-input>
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col>
                                    <el-form-item label="业务联系人：" prop="businessContractName"
                                                  style="float: left;width: 320px">
                                        <el-input placeholder="姓名" maxlength="30" :disabled="!showButton"
                                                  v-model="form.businessContractName"></el-input>
                                    </el-form-item>
                                    <el-form-item prop="businessContractPhone" label-width="10px" style="float: left;">
                                        <div style="width: 180px">
                                            <el-input placeholder="手机号" maxlength="11" :disabled="!showButton"
                                                      v-model="form.businessContractPhone"></el-input>
                                        </div>
                                    </el-form-item>

                                    <el-form-item label-width="10px" style="float: left;">
                                        <div style="width: 180px">
                                            <el-input placeholder="邮箱" :disabled="!showButton"
                                                      v-model="form.businessContractMail"></el-input>
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col v-if="form.supplierSelect||form.operatorSelect">
                                    <el-form-item label="财务联系人：" prop="financialContractName"
                                                  style="float: left;width: 320px">
                                        <el-input placeholder="姓名" maxlength="30" :disabled="!showButton"
                                                  v-model="form.financialContractName"></el-input>
                                    </el-form-item>

                                    <el-form-item prop="financialContractPhone" label-width="10px" style="float: left;">
                                        <div style="width: 180px">
                                            <el-input placeholder="手机号" maxlength="11" :disabled="!showButton"
                                                      v-model="form.financialContractPhone"></el-input>
                                        </div>

                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-card>
                        <el-card class="box-card" v-if="form.supplierSelect||form.operatorSelect">
                            <p class="add-label"><span>财务信息</span><span
                                style="color: #0053A0;padding-left: 83px;font-weight: normal">需与《开户许可证》上的信息保持一致</span>
                            </p>
                            <el-form-item label="开户名称：" prop="openAccountName">
                                <div style="width: 380px">
                                    <el-input v-model="form.openAccountName" :disabled="!showButton"></el-input>
                                </div>
                            </el-form-item>
                            <el-form-item label="开户银行：" prop="openAccountBank">
                                <div style="width: 380px">
                                    <el-input v-model="form.openAccountBank" :disabled="!showButton"></el-input>
                                </div>
                            </el-form-item>
                            <el-form-item label="对公银行账号：" prop="publicBankAccount">
                                <div style="width: 380px">
                                    <el-input v-model="form.publicBankAccount" :disabled="!showButton"></el-input>
                                </div>
                            </el-form-item>
                            <el-form-item label="结算方式：" prop="settleType">
                                <el-radio-group v-model="form.settleType" :disabled="!showButton">
                                    <el-radio label="1">单结</el-radio>
                                    <el-radio label="2">周结（T+3）</el-radio>
                                    <el-radio label="3">月结（T+15）<span style="color: #0053A0;font-weight: normal">按照订单行程结束日期起算</span>
                                    </el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-card>
                        <el-card class="box-card">
                            <p class="add-label"><span>资质信息</span><span
                                style="color: #909399;padding-left: 43px;font-weight: normal">请勾选已有（已收集）资质</span>
                            </p>
                            <el-row>
                                <el-col>
                                    <el-form-item label-width="80px" prop="businessLincense">
                                        <div style="display: flex">
                                            <el-checkbox v-model="form.businessLincense" :disabled="!showButton">
                                                《工商营业执照》
                                            </el-checkbox>
                                            <file-upload v-if="showButton" class="table-img-box-upload"
                                                         id="businessLincensePhoto"
                                                         :showFileList="false"
                                                         :customStyle="true"
                                                         accept="image/*, application/pdf"
                                                         @onCompleted="handleImageUpload">
                                                <el-button size="small" style="">
                                                    <i class="icon icon-upload-arrow"></i>上传
                                                </el-button>
                                            </file-upload>
                                        </div>
                                        <div v-if="form.businessLincensePhoto" class="preview_img">
                                            <see-pdf :src="form.businessLincensePhoto"
                                                     v-if="isPdf.businessLincensePhoto"></see-pdf>
                                            <el-image v-else
                                                      :src="form.businessLincensePhoto">
                                            </el-image>
                                            <el-button type="text"
                                                       @click="clickLookPicture('businessLincensePhoto', form.businessLincensePhoto)"
                                                       class="see-big">查看大图
                                            </el-button>
                                        </div>
                                        <div v-else>
                                            暂无工商营业执照
                                        </div>


                                        <!--                                        <el-button v-if="form.businessLincense">上传</el-button>-->
                                    </el-form-item>
                                </el-col>
                                <el-col v-if="form.supplierSelect||form.operatorSelect">
                                    <el-form-item label-width="80px" prop="bankDepositPermit">
                                        <el-checkbox v-model="form.bankDepositPermit" :disabled="!showButton">《开户行许可证》
                                        </el-checkbox>
                                    </el-form-item>
                                </el-col>
                                <el-col v-if="form.supplierSelect&&isRoad">
                                    <el-col :span="4">
                                        <el-form-item label-width="80px">
                                            <el-checkbox v-model="form.roadOperationLicense" :disabled="!showButton">
                                                《道路运营经营许可证》
                                            </el-checkbox>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="20">
                                        <el-form-item label="有效期">
                                            <el-date-picker value-format="yyyy-MM-dd 00:00:00" :disabled="!showButton"
                                                            type="daterange"
                                                            v-model="form.roadOperationDate"
                                                            range-separator="至"
                                                            start-placeholder="开始日期"
                                                            end-placeholder="结束日期">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-col>

                                <!--                                <el-col v-if="form.supplierSelect&&isTravel">-->
                                <el-col v-if="isTravel">
                                    <el-form-item label-width="80px" prop="travelBusinessLicense">
                                        <div style="display: flex">
                                            <el-checkbox v-model="form.travelBusinessLicense" :disabled="!showButton">
                                                <!--                                            《旅行社业务经营许可证》-->
                                                《旅行社经营许可证》
                                            </el-checkbox>

                                            <file-upload v-if="showButton"
                                                         class="table-img-box-upload"
                                                         id="travelBusinessLicensePhoto"
                                                         :showFileList="false"
                                                         :customStyle="true"
                                                         accept="image/*, application/pdf"
                                                         @onCompleted="handleImageUpload">
                                                <el-button size="small" style="">
                                                    <i class="icon icon-upload-arrow"></i>上传
                                                </el-button>
                                            </file-upload>
                                        </div>
                                        <div v-if="form.travelBusinessLicensePhoto" class="preview_img">
                                            <see-pdf :src="form.travelBusinessLicensePhoto"
                                                     v-if="isPdf.travelBusinessLicensePhoto"></see-pdf>
                                            <el-image v-else
                                                      :src="form.travelBusinessLicensePhoto">
                                            </el-image>
                                            <el-button type="text"
                                                       @click="clickLookPicture('travelBusinessLicensePhoto', form.travelBusinessLicensePhoto)"
                                                       class="see-big">查看大图
                                            </el-button>
                                        </div>
                                        <div v-else>
                                            暂无旅行社经营许可证
                                        </div>
                                        <!--                                        <el-button v-if="form.travelBusinessLicense">上传</el-button>-->
                                    </el-form-item>
                                    <!--                                    <el-form-item label-width="80px" prop="powerAttorney">-->
                                    <el-form-item label-width="80px">
                                        <!--                                        <el-checkbox v-model="form.isPowerAttorney" :disabled="!showButton">-->
                                        <!--                                            &lt;!&ndash;                                            《旅行社业务经营许可证》&ndash;&gt;-->
                                        <!--                                            -->
                                        <!--                                        </el-checkbox>-->
                                        <div style="display: flex">
                                            《授权委托书》
                                            <file-upload v-if="showButton" class="table-img-box-upload"
                                                         id="powerAttorney"
                                                         :showFileList="false"
                                                         :customStyle="true"
                                                         accept="image/*, application/pdf"
                                                         @onCompleted="handleImageUpload">
                                                <el-button size="small" style="">
                                                    <i class="icon icon-upload-arrow"></i>上传
                                                </el-button>
                                            </file-upload>
                                        </div>
                                        <div v-if="form.powerAttorney" class="preview_img">
                                            <see-pdf :src="form.powerAttorney" v-if="isPdf.powerAttorney"></see-pdf>
                                            <el-image v-else
                                                      :src="form.powerAttorney">
                                            </el-image>
                                            <el-button type="text"
                                                       @click="clickLookPicture('powerAttorney', form.powerAttorney)"
                                                       class="see-big">查看大图
                                            </el-button>
                                        </div>
                                        <div v-else>
                                            暂无授权委托书
                                        </div>
                                        <!--                                        <el-button v-if="form.isPowerAttorney">上传</el-button>-->
                                    </el-form-item>
                                </el-col>
                                <el-col v-if="form.supplierSelect&&isTravel">
                                    <el-col :span="4">
                                        <el-form-item label-width="80px" prop="travelInsurance">
                                            <el-checkbox v-model="form.travelInsurance" :disabled="!showButton">
                                                《旅行社责任保险》
                                            </el-checkbox>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="18">
                                        <el-form-item label="有效期" prop="travelDate">
                                            <el-date-picker value-format="yyyy-MM-dd 00:00:00" v-model="form.travelDate"
                                                            :disabled="!showButton"
                                                            type="daterange"
                                                            range-separator="至"
                                                            start-placeholder="开始日期"
                                                            end-placeholder="结束日期">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col v-if="form.secondSelect">
                                    <el-form-item label-width="80px" prop="travelRelationship">
                                        <el-checkbox v-model="form.travelRelationship" :disabled="!showButton">
                                            《旅行社挂靠关系证明》
                                        </el-checkbox>
                                    </el-form-item>
                                </el-col>
                                <el-col>
                                    <el-col :span="1">
                                        <el-form-item label-width="80px">
                                            <el-checkbox v-model="form.otherQual" :disabled="!showButton">其他：
                                            </el-checkbox>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="22" v-if="form.otherQual">
                                        <el-form-item>
                                            <el-input v-model="form.comment" :disabled="!showButton"
                                                      placeholder="可备注法人身份证等，汉字/字母/数字，不超过200字"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-col>

                            </el-row>
                        </el-card>
                        <el-card class="box-card">
                            <p class="add-label">合同信息</p>
                            <el-row>
                                <div v-for="(contract,index) in contractListData" :key="index">
                                    <div style="width: 100%;display: inline-block"
                                         v-for="(it,idx) in contract.contractType" :key="idx">
                                        <el-form-item :label="it.userName" label-width="120px"
                                                      style="float: left;width: 430px">
                                            <el-checkbox v-model="it.isChecked" :disabled="!showButton">
                                                <span v-if="it.name||!showButton">《{{ it.name || it.editName }}》</span>
                                                <el-input v-else :disabled="!showButton" placeholder="合同名称"
                                                          clearable
                                                          v-model="it.editName"></el-input>
                                            </el-checkbox>
                                        </el-form-item>
                                        <el-form-item label="有效期：" style="float: left;" label-width="80px">
                                            <el-date-picker
                                                value-format="yyyy-MM-dd 00:00:00" :disabled="!showButton"
                                                type="daterange"
                                                range-separator="至"
                                                start-placeholder="开始日期"
                                                style="width: 220px"
                                                end-placeholder="结束日期" v-model="it.validityDate">
                                            </el-date-picker>
                                        </el-form-item>
                                        <el-form-item label-width="20px" style="float: left;">
                                            <el-input placeholder="合同编号，字母/数字，不超过20个字"
                                                      :disabled="!showButton" v-model="it.contractNum"
                                                      style="width: 300px"></el-input>
                                        </el-form-item>
                                        <el-form-item v-if="!it.name&&showButton" label-width="10px"
                                                      style="float: left;">
                                            <el-button v-if="idx<contract.contractType.length-1" icon="el-icon-minus"
                                                       size="mini" type="danger"
                                                       circle
                                                       @click="addContractType(contract.type,idx,0)"></el-button>
                                            <el-button v-else icon="el-icon-plus"
                                                       size="mini" type="primary" circle
                                                       @click="addContractType(contract.type,idx,1)"></el-button>
                                        </el-form-item>
                                    </div>


                                </div>
                            </el-row>
                        </el-card>
                        <el-card class="box-card" v-if="form.secondSelect||form.firstSelect">
                            <p class="add-label">财务信息</p>
                            <el-row>
                                <el-form-item label="是否财务独立：" prop="isFinancialAutonomy" v-if="form.secondSelect">
                                    <el-radio-group v-model="form.isFinancialAutonomy" :disabled="!showButton">
                                        <el-radio :label="1">是</el-radio>
                                        <el-radio :label="0">否</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <p v-if="form.firstSelect"
                                   style="font-weight: 400;color:rgba(51,51,51,1);font-size: 14px;line-height: 40px;padding-left: 30px;">
                                    默认财务独立</p>
                            </el-row>
                        </el-card>
                    </div>
                </el-form>
            </div>
        </el-main>

        <!--查看大图  popup  begin-->
        <el-dialog class="dialog-look-picture" custom-class="dialog-look-picture-custom" title="查看" center
                   :visible.sync="lookPictureImg">
            <p v-if="lookPictureImgUrl">
                <el-button class="original-image-btn" type="text">
                    <a :href="lookPictureImgUrl" target="_blank">点击查看原图</a>
                </el-button>
                <see-pdf :src="lookPictureImgUrl" v-if="isSeePdf"></see-pdf>
                <img :src="lookPictureImgUrl" v-else>
                <!--                <img :src="lookPictureImgUrl">-->
            </p>
            <p style="text-align: center;padding-top: 20px" v-else>暂无照片</p>
        </el-dialog>
        <!--查看大图  popup end-->

        <el-footer class="com-eTrip-footer" height="64px" v-if="showInput">
            <div class="eTrip-footer-cont">
                <div style="text-align: right">
                    <el-row v-if="!showAdd">
                        <div v-if="showButton" style="float: right">
                            <div v-if="form.editShow">
                                <el-button @click="validateForm(3)">保存</el-button>
                                <el-button type="primary" @click="validateForm(4)">提交</el-button>
                            </div>
                        </div>
                        <div style="line-height: 40px;float: right;margin-right: 20px">
                            <el-radio-group v-model="showButton" @change="editChange">
                                <el-radio :label="false">查看模式</el-radio>
                                <el-radio :label="true" v-if="form.editShow">编辑模式</el-radio>
                            </el-radio-group>
                        </div>
                    </el-row>
                    <el-row v-if="showAdd">
                        <el-col :span="3" style="float: right;width: 160px">
                            <div>
                                <el-button @click="validateForm(1)">保存</el-button>
                                <el-button type="primary" @click="validateForm(2)">提交</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </el-footer>
    </div>


</template>

<script type="text/ecmascript-6">
import {
    isSpecialChart,
    onlyLettersOrNumber,
    onlyChineseAndLetter,
    onlyNumber,
    isIDCardNumber,
    checkStringFun
} from '@/common/js/Utils';
import filterVue from 'vue';
import {getCityData} from '@/urls/index';
import CascaderCity from '@/components/cascader-city/index'
import seePdf from 'vue-pdf'
import fileUpload from '@/components/fileUpload'
import dayjs from "dayjs";

export const contractList = [
    {
        isShow: false,
        type: 1,
        contractType: [
            {
                userName: '一级用户：',
                isChecked: false,
                name: "用户入驻协议",
                validityDate: [],
                contractNum: ""
            },
            {
                isChecked: false,
                name: "业务合作与互为分销协议",
                validityDate: [],
                contractNum: ""
            },
            {
                isChecked: false,
                name: "旅游包车协议",
                validityDate: [],
                contractNum: ""
            },
            {
                isChecked: false,
                name: "补充协议",
                validityDate: [],
                contractNum: ""
            },
            {
                isChecked: false,
                name: "中山陵音乐台产品试运营服务协议",
                validityDate: [],
                contractNum: ""
            },
            {
                userName: "其他",
                isChecked: false,
                editName: "",
                validityDate: [],
                contractNum: ""
            }
        ]
    },
    {
        isShow: false,
        type: 2,
        contractType: [
            {
                userName: '二级用户：',
                isChecked: false,
                name: "用户入驻协议",
                validityDate: [],
                contractNum: ""
            },
            {
                isChecked: false,
                name: "业务合作与互为分销协议",
                validityDate: [],
                contractNum: ""
            },
            {
                isChecked: false,
                name: "旅游包车协议",
                validityDate: [],
                contractNum: ""
            },
            {
                isChecked: false,
                name: "补充协议",
                validityDate: [],
                contractNum: ""
            }
        ]
    },
    {
        isShow: false,
        canAdd: true,
        type: 3,
        contractType: [
            {
                userName: '供应商：',
                isChecked: false,
                name: "900游供应商合作协议",
                validityDate: [],
                contractNum: "",
                key: 'supplierNewContract'
            },
            {
                isChecked: false,
                name: "900游供应商合作协议之补充协议",
                validityDate: [],
                contractNum: "",
                key: 'supplierNewSupplementContract'
            },
            {
                isChecked: false,
                name: "900游业务合作协议",
                validityDate: [],
                contractNum: "",
                key: 'supplierBusinessContract'
            },
            {
                isChecked: false,
                name: "旅游包车协议",
                validityDate: [],
                contractNum: "",
                key: 'supplierCharteredCarContract'
            },
            {
                userName: "其他",
                isChecked: false,
                editName: "",
                validityDate: [],
                contractNum: ""
            }
        ]
    },
    {
        isShow: false,
        canAdd: true,
        type: 4,
        contractType: [
            {
                userName: '运营商：',
                isChecked: false,
                name: " 900游平台城市运营商合作协议编号",
                validityDate: [],
                contractNum: "",
                key: 'operatorNewContract'
            },
            {
                isChecked: false,
                name: " 900游平台城市运营商合作协议之补充协议",
                validityDate: [],
                contractNum: "",
                key: 'operatorNewSupplementContract'
            },
            {
                userName: "其他",
                editName: "",
                isChecked: false,
                validityDate: [],
                contractNum: ""
            }
        ]
    }
]

export default {
    //定义模版数据
    data() {
        //校验用户名称
        let validateUserName = (rule, value, callback) => {
            if (!isSpecialChart(value)) {
                callback(new Error('填写的名称格式错误'));
            } else {
                callback();
            }
        };
        let validateLettersOrNumbers = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请填写内容'));
            } else if (!onlyLettersOrNumber(value)) {
                callback(new Error('格式错误'));
            } else {
                callback();
            }
        };
        let validateName = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请填写姓名'));
            } else if (!onlyChineseAndLetter(value)) {
                callback(new Error('姓名格式错误'));
            } else {
                callback();
            }
        };
        let validatePhone = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请填写手机号'));
            } else {
                callback();
            }
        };
        let validateChecked = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请选择'));
            } else {
                callback();
            }
        };
        let validateDate = (rule, value, callback) => {
            if (!value || value.length == 0) {
                callback(new Error('请选择有效期'));
            } else {
                callback();
            }
        };
        let validateNum = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请填写银行账户'));
            } else if (!onlyNumber(value)) {
                callback(new Error('银行账户格式错误'));
            } else {
                callback();
            }
        };
        let validateCompanyPAndC = (rule, value, callback) => {
            if (this.form.firstSelect || this.form.secondSelect) callback();
            if (!value[0] && !value[1]) {
                callback(new Error('请选择省市'));
            } else {
                callback();
            }
        };
        let validateCompanyAddress = (rule, value, callback) => {
            if (this.form.firstSelect || this.form.secondSelect) callback();
            if (!value) {
                callback(new Error('请填写详细地址'));
            } else {
                callback();
            }
        };
        let validateServerCity = (rule, value, callback) => {
            console.log(value, '888888');
            if (this.form.firstSelect || this.form.secondSelect) callback();
            if (value && value.length > 0) {
                callback();
            } else {
                callback(new Error('请选择服务城市'));
            }
        };

        return {
            showButton: false,
            showAdd: true,
            isIndeterminate: true,
            isIndeterminate1: true,
            checkOrgTypeAll: false,
            checkScopeAll: false,
            // supplierList: [], //所属运营商list
            firstList: [],    //所以一级用户list
            showCity: false,
            checked: false,
            agencyTypeList: ['汽车租赁公司', '旅行社', '酒店', '门店', '会展公司', '电子商务公司', '企业单位', '事业单位', '政府部门', '其他'],
            businenssScopeList: ['车辆', '导游', '门票', '酒店', '机票', '旅游(单元素)', '旅游(打包)', '交通票', '其他'],

            lookPictureImg: false,  //查看大图
            lookPictureImgUrl: '',  //大图url

            isPdf: {
                businessLincensePhoto: false,
                travelBusinessLicensePhoto: false,
                powerAttorney: false
            },
            isSeePdf: false,  // 文件是否是pdf
            form: {
                supplierSelect: false,
                operatorSelect: false,
                firstSelect: false,
                secondSelect: false,
                orgType: [],
                businenssScope: [],
                serverCity: [],
                idType: '1',
                businessLincense: false,
                businessLincensePhoto: '',
                bankDepositPermit: false,
                roadOperationLicense: false,
                travelBusinessLicense: false,
                travelBusinessLicensePhoto: '',
                powerAttorney: '',
                travelInsurance: false,
                travelRelationship: false,
                roadOperationDate: [],
                travelDate: [],
                otherQual: false,
                editShow: false,
                settleType: '1',
                isFinancialAutonomy: 1,
                companyPAndC: []
            },
            ruleForm: {
                firstName: [{required: true, message: '请填写一级用户名称', trigger: 'change'}],
                operatorName: [{required: true, message: '请填写运营商名称', trigger: 'change'}],
                userName: [{
                    required: true,
                    message: '请填写用户名称',
                    trigger: 'blur'
                }],
                companyName: [{required: true, message: '请填写公司名称', trigger: 'blur'}],
                businessLicenseNumber: [{
                    required: true,
                    message: '请填写营业执照号码',
                    trigger: 'blur'
                }, {validator: validateLettersOrNumbers, trigger: 'blur'}],
                companyPAndC: [{validator: validateCompanyPAndC, trigger: 'change'}],
                companyAddress: [{validator: validateCompanyAddress, trigger: 'blur'}],
                serverCity: [{validator: validateServerCity, trigger: 'change'}],
                orgType: [{required: true, message: '请选择机构类型', trigger: 'change'}],
                businenssScope: [{required: true, message: '请选择经营范围', trigger: 'change'}],
                legalPersonName: [{required: true, message: '请填写姓名', trigger: 'blur'},
                    {validator: validateName, trigger: 'blur'}],
                businessContractName: [{required: true, message: '请填写姓名', trigger: 'blur'},
                    {validator: validateName, trigger: 'blur'}],
                financialContractName: [{
                    required: true,
                    message: '请填写姓名',
                    trigger: 'blur'
                }, {validator: validateName, trigger: 'blur'}],
                legalPersonPhone: [{required: true, message: '请填写手机号', trigger: 'blur'}, {
                    validator: validatePhone,
                    trigger: 'blur'
                }],
                businessContractPhone: [{
                    required: true,
                    message: '请填写手机号',
                    trigger: 'blur'
                }, {validator: validatePhone, trigger: 'blur'}],
                financialContractPhone: [{
                    required: true,
                    message: '请填写手机号',
                    trigger: 'blur'
                }, {validator: validatePhone, trigger: 'blur'}],
                idType: [{required: true, message: '请选择证件类型', trigger: 'change'}],
                idNo: [
                    {
                        validator: (rule, value, callback) => {
                            if (!value) {
                                callback(new Error('请输入证件号码'));
                            } else {
                                switch (this.form.idType) {
                                    case "1":
                                        if (!isIDCardNumber(value)) {
                                            callback(new Error('证件号码格式错误'));
                                        }
                                        break;
                                    case "2":
                                        if (!onlyLettersOrNumber(value)) {
                                            callback(new Error('证件号码格式错误'));
                                        }
                                        break;
                                }
                                callback();
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                businessLincense: [{validator: validateChecked, trigger: 'change'}],
                bankDepositPermit: [{validator: validateChecked, trigger: 'change'}],
                roadOperationLicense: [{validator: validateChecked, trigger: 'change'}],
                travelBusinessLicense: [{validator: validateChecked, trigger: 'change'}],
                travelInsurance: [{validator: validateChecked, trigger: 'change'}],
                travelRelationship: [{validator: validateChecked, trigger: 'change'}],
                roadOperationDate: [{validator: validateDate, trigger: 'change'}],
                travelDate: [{validator: validateDate, trigger: 'change'}],
                openAccountName: [{
                    required: true,
                    message: '请填写开户名称',
                    trigger: 'blur'
                }],
                openAccountBank: [{
                    required: true,
                    message: '请填写开户银行',
                    trigger: 'blur'
                }, {validator: validateUserName, trigger: 'blur'}],
                publicBankAccount: [{
                    required: true,
                    message: '请填写对公银行账号',
                    trigger: 'blur'
                }, {validator: validateNum, trigger: 'blur'}],
                settleType: [{required: true, message: '请选择结算方式', trigger: 'change'}],
                isFinancialAutonomy: [{required: true, message: '请选择是否财务独立', trigger: 'change'}],
            },
            isTravel: false,
            isRoad: false,
            operatorList: [],
            serverCityList: [],
            contractList: JSON.parse(JSON.stringify(contractList)),
            resetContract: JSON.parse(JSON.stringify(contractList))
        }
    },
    components: {
        CascaderCity,
        seePdf,
        fileUpload
    },
    //计算属性
    computed: {
        showInput() {
            let isShow = false;
            if (this.form.firstSelect ||
                this.form.secondSelect ||
                this.form.supplierSelect ||
                this.form.operatorSelect) {
                isShow = true;
            }
            return isShow;
        },
        userInfo() {
            return this.$store.state.loginUserInfo
        },
        contractListData() {
            return this.contractList.filter(({isShow}) => isShow === true)
        }
    },
    activated: function () {
        this.init();
    },
    //主件被加载完成
    mounted: function () {
        this.init();
    },
    //定义事件方法
    methods: {
        init: async function () {
            this.loadingShow();
            this.getOperator();
            this.getServerCity();
            switch (this.$route.name) {
                case 'editMechanism':
                    this.showButton = true;
                    this.showAdd = false;
                    await this.getOrgEditInfo();
                    break;
                case 'LookMechanism':
                    this.showButton = false;
                    this.showAdd = false;
                    await this.getOrgInfo();
                    break;
                default:
                    this.showButton = true;
                    this.showAdd = true;
                    this.form = {
                        supplierSelect: false,
                        operatorSelect: false,
                        firstSelect: false,
                        secondSelect: false,
                        orgType: [],
                        businenssScope: [],
                        serverCity: [],
                        idType: '1',
                        businessLincense: false,
                        businessLincensePhoto: '',
                        bankDepositPermit: false,
                        roadOperationLicense: false,
                        travelBusinessLicense: false,
                        travelBusinessLicensePhoto: '',
                        powerAttorney: '',
                        travelInsurance: false,
                        travelRelationship: false,
                        roadOperationDate: [],
                        travelDate: [],
                        settleType: '1',
                        companyPAndC: []
                    };
                    break;
            }
            this.loadingHide();
        },
        handleIsFilePdf(id, fileUrl) {
            let form = {
                id,
                isPdf: false
            }
            if (fileUrl) {
                let suffix = '';
                let result = '';
                try {
                    let fileArr = fileUrl.split('.');
                    suffix = fileArr[fileArr.length - 1];
                } catch (err) {
                    suffix = '';
                }
                if (!suffix) {
                    result = false;
                    form.isPdf = result;
                    return form;
                }
                let pdfList = ['pdf'];
                let fileRes = pdfList.some(item => {
                    return item == suffix
                })
                form.isPdf = fileRes;
                return form
            }
        },
        async selectSupplier() {//获取运营商list
            if (this.showButton) {
                this.form.supplierSelect = !this.form.supplierSelect;
                this.contractList[2].isShow = this.form.supplierSelect;
            }
        },
        selectOperator() {
            if (this.showButton) {
                this.form.operatorSelect = !this.form.operatorSelect;
                this.contractList[3].isShow = this.form.operatorSelect;
            }
        },
        async getServerCity() {
            const ret = await getCityData(this, '2');
            if (ret)
                this.serverCityList = ret;
        },
        async getOperator() {//获取运营商list
            let res = await this.http('/galaxyOperator/operatorInfoList', '', 'POST');
            // console.log(res);
            if (res && res.success) {
                this.operatorList = res.data;
            }
        },
        async selectSecName() {//获取一级用户list
            if (this.showButton) {
                this.form.secondSelect = !this.form.secondSelect;
                this.form.firstSelect = this.form.secondSelect ? false : this.form.firstSelect;
                this.contractList[0].isShow = this.form.firstSelect;
                this.contractList[1].isShow = this.form.secondSelect;
                this.form.isFinancialAutonomy = 1;
                if (!this.form.secondSelect) return false;
                try {
                    let res = await this.http('/galaxyMerchant/merchantInfoList', '', 'POST');
                    if (res.success) {
                        this.firstList = res.data;
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        },
        selectFirstName() {
            if (this.showButton) {
                this.form.firstSelect = !this.form.firstSelect;
                this.form.secondSelect = this.form.firstSelect ? false : this.form.secondSelect;
                this.contractList[0].isShow = this.form.firstSelect;
                this.contractList[1].isShow = this.form.secondSelect;
                this.form.isFinancialAutonomy = 1;
            }
        },
        handleCheckAllChange(val) {
            // console.log(val);
            this.isRoad = val;
            this.isTravel = val;
            this.form.orgType = val ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] : [];//this.agencyTypeList : [];
            this.isIndeterminate = !val;
        },
        handleCheckAllChange1(val) {
            // console.log(val);
            this.form.businenssScope = val ? [1, 2, 3, 4, 5, 6, 7, 8, 9] : []//this.businenssScopeList : [];
            this.isIndeterminate1 = !val;
        },
        getOrgType(val) {
            // console.log(val, 1231231231231231);
            this.isRoad = false;
            this.isTravel = false;
            val.forEach(item => {
                if (item == 1) this.isRoad = true;
                if (item == 2) this.isTravel = true;
            })
            let checkedCount = val.length;
            this.checkOrgTypeAll = checkedCount === this.agencyTypeList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.agencyTypeList.length;
        },
        getBusinessScope(val) {
            // console.log(val);
            let checkedCount = val.length;
            this.checkScopeAll = checkedCount === this.businenssScopeList.length;
            this.isIndeterminate1 = checkedCount > 0 && checkedCount < this.businenssScopeList.length;
        },
        clickLookPicture(id, pictureUrl) {  // 查看图片
            this.lookPictureImg = !this.lookPictureImg;
            this.lookPictureImgUrl = pictureUrl;
            let isPdfForm = {};
            isPdfForm = this.handleIsFilePdf(id, pictureUrl);
            this.isSeePdf = isPdfForm.isPdf;

        },
        handleImageUpload({id, url}) {
            let isPdfForm = {};
            isPdfForm = this.handleIsFilePdf(id, url);
            switch (id) {
                case 'businessLincensePhoto':
                    this.form.businessLincensePhoto = url;
                    this.isPdf.businessLincensePhoto = isPdfForm.isPdf;
                    // this.$refs.form.validateField('businessLincensePhoto');
                    break;
                case 'travelBusinessLicensePhoto':
                    this.form.travelBusinessLicensePhoto = url;
                    this.isPdf.travelBusinessLicensePhoto = isPdfForm.isPdf;
                    // this.$refs.form.validateField('travelBusinessLicensePhoto');
                    break;
                case 'powerAttorney':
                    this.form.powerAttorney = url;
                    this.isPdf.powerAttorney = isPdfForm.isPdf;
                    // this.$refs.form.validateField('powerAttorney');
                    break;
            }
        }, //营业执照
        validateForm(t) {
            this.$refs.rule.validate((valid) => {
                if (valid) {
                    const companyPAndC = this.form.companyPAndC
                    let form = {
                        bankDepositPermit: this.form.bankDepositPermit ? '1' : '0',     //开户行许可证
                        businessContractName: this.form.businessContractName,//业务联系人姓名
                        businessContractPhone: this.form.businessContractPhone,
                        businessLicenseNumber: this.form.businessLicenseNumber,
                        businessLincense: this.form.businessLincense ? 1 : 0,
                        businessLincensePhoto: this.form.businessLincensePhoto,
                        businessScope: checkStringFun(9, this.form.businenssScope),//this.form.businenssScope.join(','),
                        comment: this.form.comment,
                        companyAddress: this.form.companyAddress,
                        companyCity: companyPAndC[1],
                        companyName: this.form.companyName,
                        companyProvince: companyPAndC[0],
                        financialContractName: this.form.financialContractName,
                        financialContractPhone: this.form.financialContractPhone,
                        idNo: this.form.idNo,
                        idType: this.form.idType,
                        legalPersonName: this.form.legalPersonName,
                        legalPersonPhone: this.form.legalPersonPhone,
                        openAccountBank: this.form.openAccountBank,
                        openAccountName: this.form.openAccountName,
                        operatorValidate: this.form.operatorValidate,
                        orgType: checkStringFun(10, this.form.orgType),//this.form.orgType.join(','),
                        publicBankAccount: this.form.publicBankAccount,
                        roadOperationLicense: this.form.roadOperationLicense ? 1 : 0,
                        serverCity: this.form.serverCity,
                        settleType: this.form.settleType,
                        travelBusinessLicense: this.form.travelBusinessLicense ? 1 : 0,
                        travelBusinessLicensePhoto: this.form.travelBusinessLicensePhoto,
                        powerAttorney: this.form.powerAttorney,
                        travelInsurance: this.form.travelInsurance ? 1 : 0,
                        travelRelationship: this.form.travelRelationship ? 1 : 0,
                        userName: this.form.userName,
                        operatorId: this.form.operatorName,
                        businessContractMail: this.form.businessContractMail,
                        firstMerchantId: this.form.firstName,
                        isFinancialAutonomy: this.form.isFinancialAutonomy,
                    };
                    let type = this.form.firstSelect ? '1' : '0',
                        type1 = this.form.secondSelect ? '1' : '0',
                        type2 = this.form.supplierSelect ? '1' : '0',
                        type3 = this.form.operatorSelect ? '1' : '0';
                    form.userType = type + type1 + type2 + type3;
                    if (form.businessScope.charAt(9) == '1') form.businessScope += this.form.otherBusinenss;
                    if (this.form.travelDate && this.form.travelDate.length > 0) {
                        this.form.travelDate[0] && (form.travelInsuranceBegin = +new Date(this.form.travelDate[0]));
                        this.form.travelDate[1] && (form.travelInsuranceEnd = +new Date(this.form.travelDate[1]));
                    }
                    if (this.form.roadOperationDate && this.form.roadOperationDate.length > 0) {
                        this.form.roadOperationDate[0] && (form.roadOperationLicenseBegin = +new Date(this.form.roadOperationDate[0]));
                        this.form.roadOperationDate[1] && (form.roadOperationLicenseEnd = +new Date(this.form.roadOperationDate[1]));
                    }
                    const initContract = JSON.parse(this.form.contractJson || '{}');

                    let contractData = {
                        //这里改过字段 老数据在编辑是 还是默认带回去
                        supplier: initContract.supplier,
                        operator: initContract.operator
                    };

                    let firstMerchant = [], secondMerchant = [], zslProduct = [];
                    let pass = true;
                    let filterDate = filterVue.filter("dateCus");
                    this.contractList.forEach((item, index) => {
                        if (item.isShow) {
                            item.contractType.forEach((item1) => {
                                if (item1.isChecked) {
                                    if (!item1.validityDate || item1.validityDate.length < 2)
                                        return pass = false;
                                    else {
                                        let contract = {
                                            name: item1.name || item1.editName,
                                            effectiveDate: filterDate(+new Date(item1.validityDate[0]), 'yyyy-MM-dd'),
                                            expirationDate: filterDate(+new Date(item1.validityDate[1]), 'yyyy-MM-dd'),
                                            contractNum: item1.contractNum
                                        }
                                        switch (index) {
                                            case 0:
                                                firstMerchant.push(contract);
                                                break;
                                            case 1:
                                                secondMerchant.push(contract);
                                                break;
                                            case 2:
                                                if (item1.key) {
                                                    form[`${item1.key}No`] = contract.contractNum;
                                                    form[`${item1.key}Begin`] = contract.effectiveDate;
                                                    form[`${item1.key}End`] = contract.expirationDate;
                                                } else {
                                                    form['supplierOtherContract'] = form['supplierOtherContract'] || [];
                                                    form['supplierOtherContract'].push({
                                                        contractNo: contract.contractNum,
                                                        contractName: item1.editName,
                                                        contractBegin: contract.effectiveDate,
                                                        contractEnd: contract.expirationDate
                                                    })
                                                }
                                                // supplier.push(contract);
                                                break;
                                            case 3:
                                                if (item1.key) {
                                                    form[`${item1.key}No`] = contract.contractNum;
                                                    form[`${item1.key}Begin`] = contract.effectiveDate;
                                                    form[`${item1.key}End`] = contract.expirationDate;
                                                } else {
                                                    form['operatorOtherContract'] = form['operatorOtherContract'] || [];
                                                    form['operatorOtherContract'].push({
                                                        contractNo: contract.contractNum,
                                                        contractName: item1.editName,
                                                        contractBegin: contract.effectiveDate,
                                                        contractEnd: contract.expirationDate
                                                    })
                                                }
                                                // operator.push(contract);
                                                break;
                                            case 4:
                                                zslProduct.push(contract);
                                                break;
                                        }
                                        pass = true;
                                    }
                                }

                            })
                        }
                    })
                    if (!pass) return this.$message.error('请选择合同有效期');
                    if (this.form.firstSelect && firstMerchant.length == 0 && this.form.tdsTravelBusiness === 0) return this.$message.error('请选择一级用户合同');
                    if (this.form.secondSelect && secondMerchant.length == 0) return this.$message.error('请选择二级用户合同');
                    // if (this.form.supplierSelect && supplier.length == 0) return this.$message.error('请选择供应商合同');
                    // if (this.form.operatorSelect && operator.length == 0) return this.$message.error('请选择运营商合同');
                    if (firstMerchant.length > 0) contractData.firstMerchant = firstMerchant;
                    if (secondMerchant.length > 0) contractData.secondMerchant = secondMerchant;
                    // if (supplier.length > 0) contractData.supplier = supplier;
                    // if (operator.length > 0) contractData.operator = operator;
                    form.contractJson = JSON.stringify(contractData);
                    if (form.operatorOtherContract) {
                        form.operatorOtherContract = JSON.stringify(form.operatorOtherContract)
                    }
                    if (form.supplierOtherContract) {
                        form.supplierOtherContract = JSON.stringify(form.supplierOtherContract)
                    }
                    this.loadingShow();
                    switch (parseInt(t)) {
                        case 1:
                            this.save(form);
                            break;
                        case 2:
                            this.sub(form);
                            break;
                        case 3:
                            this.saveEdit(form);
                            break;
                        case 4:
                            this.subEdit(form);
                            break;
                    }
                } else this.$message.error('信息不全，请填写完整')
            })
        },
        async save(form) {
            let res = await this.http('/galaxyOrgInfoApi/post', form, 'POST');
            this.loadingHide();
            if (res && res.success) {
                this.$router.push('/mechanismList');
            } else {
                this.$message.error(res.errors[0].message);
            }
        },
        async sub(form) {
            let res = await this.http('/galaxyOrgInfoApi/saveAndSubmitApproval', form, 'POST');
            // console.log(res);
            this.loadingHide();
            if (res && res.success) {
                this.$router.push('/mechanismList');
            } else {
                this.$message.error(res.errors[0].message);
            }
        },
        async subEdit(form) {
            form.id = this.$route.params.id;
            let res = await this.http('/galaxyOrgInfoApi/editAndSubmitApproval', form, 'PUT');
            // console.log(res);
            this.loadingHide();
            if (res && res.success) {
                this.$router.push('/mechanismList');
            } else {
                this.$message.error(res.errors[0].message)
            }
        },
        async saveEdit(form) {
            form.id = this.$route.params.id;
            let res = await this.http('/galaxyOrgInfoApi/edit/post', form, 'PUT');
            // console.log(res);
            this.loadingHide();
            if (res && res.success) {
                this.$router.push('/mechanismList');
            } else {
                this.$message.error(res.errors[0].message);
            }
        },
        async getOrgInfo() {
            let id = this.$route.params.id;
            try {
                let res = await this.http('/galaxyOrgInfoApi/get/' + id);
                this.loadingHide();
                if (res && res.success) {
                    this.form = this.getForm(res.data);
                    if (this.form.orgType.length == 10) {
                        this.checkOrgTypeAll = true;
                        this.isIndeterminate = false;
                    }
                    if (this.form.businenssScope.length == 9) {
                        this.checkScopeAll = true;
                        this.isIndeterminate1 = false;
                    }
                } else {
                    this.messageError(res.errors[0].message)
                }
            } catch (e) {
                this.messageError()
            }
        },
        async getOrgEditInfo() {
            let id = this.$route.params.id;
            let res = await this.http('/galaxyOrgInfoApi/edit/get/' + id);
            this.loadingHide();
            if (res && res.success) {
                this.form = {
                    ...this.form,
                    ...this.getForm(res.data)
                };
                if (this.form.orgType.length == 10) {
                    this.checkOrgTypeAll = true;
                    this.isIndeterminate = false;
                }
                if (this.form.businenssScope.length == 9) {
                    this.checkScopeAll = true;
                    this.isIndeterminate1 = false;
                }
            } else {
                this.messageError(res.errors[0].message)
            }
        },
        getCheckArray(str) {
            let arr = str.split('');
            let checkList = [];
            // console.log(arr);
            for (let j = 0; j < arr.length; j++) {
                if (arr[j] == 1) checkList.push(j + 1);
            }
            return checkList;
        },
        getForm(data) {
            let form = data;
            this.contractList = JSON.parse(JSON.stringify(this.resetContract));
            form.supplierSelect = data.userType.charAt(2) == '1' ? true : false;
            if (form.supplierSelect) this.selectSupplier();
            this.contractList[2].isShow = form.supplierSelect;
            form.operatorSelect = data.userType.charAt(3) == '1' ? true : false;
            this.contractList[3].isShow = form.operatorSelect;
            form.firstSelect = data.userType.charAt(0) == '1' ? true : false;
            this.contractList[0].isShow = form.firstSelect;
            form.secondSelect = data.userType.charAt(1) == '1' ? true : false;
            this.contractList[1].isShow = form.secondSelect;
            if (form.secondSelect) this.selectSecName();
            form.operatorName = data.operatorId;
            form.firstName = data.firstMerchantId;
            form.companyPAndC = [data.companyProvince, data.companyCity];
            form.companyProvince = data.companyProvince;
            form.companyCity = data.companyCity;
            form.orgType = this.getCheckArray(data.orgType);
            form.orgType.forEach(item => {
                if (item == 1) this.isRoad = true;
                if (item == 2) this.isTravel = true;
            })
            form.businenssScope = this.getCheckArray(data.businessScope.substr(0, 9));
            if (data.businessScope.charAt(9) == 1) form.otherBusinenss = data.businessScope.substr(9, data.businenssScope.length - 1);
            form.serverCity = data.serverCity ? data.serverCity : [];
            form.businessLincense = data.businessLincense == 1 ? true : false;
            if (data.businessLincensePhoto) {
                this.isPdf.businessLincensePhoto = this.handleIsFilePdf('businessLincensePhoto', data.businessLincensePhoto).isPdf;
                form.businessLincensePhoto = data.businessLincensePhoto;
            }
            form.bankDepositPermit = data.bankDepositPermit == 1 ? true : false;
            form.roadOperationLicense = data.roadOperationLicense == 1 ? true : false;
            form.travelBusinessLicense = data.travelBusinessLicense == 1 ? true : false;

            if (data.travelBusinessLicensePhoto) {
                this.isPdf.travelBusinessLicensePhoto = this.handleIsFilePdf('travelBusinessLicensePhoto', data.travelBusinessLicensePhoto).isPdf;
                form.travelBusinessLicensePhoto = data.travelBusinessLicensePhoto;
            }
            if (data.powerAttorney) {
                this.isPdf.powerAttorney = this.handleIsFilePdf('powerAttorney', data.powerAttorney).isPdf;
                form.powerAttorney = data.powerAttorney;
            }
            form.travelInsurance = data.travelInsurance == 1 ? true : false;
            form.travelRelationship = data.travelRelationship == 1 ? true : false;
            form.roadOperationDate = [];
            form.otherQual = data.comment ? true : false;
            let filterDate = filterVue.filter("dateCus");
            if (data.roadOperationLicenseBegin && data.roadOperationLicenseEnd) {
                form.roadOperationDate = [filterDate(data.roadOperationLicenseBegin, 'yyyy-MM-dd'), filterDate(data.roadOperationLicenseEnd, 'yyyy-MM-dd')];
            }
            form.travelDate = [];
            if (data.travelInsuranceBegin && data.travelInsuranceEnd) {
                form.travelDate = [filterDate(data.travelInsuranceBegin, 'yyyy-MM-dd'), filterDate(data.travelInsuranceEnd, 'yyyy-MM-dd')];
            }
            let contractInfo = data.contractJson ? JSON.parse(data.contractJson) : [];
            this.contractList.forEach((item, index) => {
                item.contractType.forEach((item1) => {
                    switch (index) {
                        case 0:
                            if (contractInfo.firstMerchant) {
                                contractInfo.firstMerchant.forEach(s => {
                                    if (s.name == item1.name) {
                                        s.used = true;
                                        item1.isChecked = true;
                                        item1.validityDate = [(s.effectiveDate + ' 00:00:00'), (s.expirationDate + ' 00:00:00')];
                                        item1.contractNum = s.contractNum;
                                    }
                                })
                            }
                            break;
                        case 1:
                            if (contractInfo.secondMerchant) {
                                contractInfo.secondMerchant.forEach(s => {
                                    if (s.name == item1.name) {
                                        item1.isChecked = true;
                                        item1.validityDate = [(s.effectiveDate + ' 00:00:00'), (s.expirationDate + ' 00:00:00')];
                                        item1.contractNum = s.contractNum;
                                    }
                                })
                            }
                            break;
                        case 2:
                        case 3:
                            if (item1.key) {
                                const start = form[`${item1.key}Begin`];
                                const end = form[`${item1.key}End`];
                                item1.validityDate = start && end ? [dayjs(start).format('YYYY-MM-DD 00:00:00'), dayjs(end).format('YYYY-MM-DD 00:00:00')] : [];
                                item1.contractNum = form[`${item1.key}No`];
                                item1.isChecked = start && end && item1.contractNum ? true : false;
                            }
                            break;
                        case 4:
                            if (contractInfo.zslProduct) {
                                contractInfo.zslProduct.forEach(s => {
                                    if (s.name == item1.name) {
                                        item1.isChecked = true;
                                        item1.validityDate = [(s.effectiveDate + ' 00:00:00'), (s.expirationDate + ' 00:00:00')];
                                        item1.contractNum = s.contractNum;
                                    }
                                })
                            }
                            break;
                    }
                })
            })
            if (form.supplierOtherContract) {
                JSON.parse(form.supplierOtherContract).forEach((item, index) => {
                    if (index === 0) {
                        this.contractList[2].contractType[this.contractList[2].contractType.length - 1].isChecked = true;
                        this.contractList[2].contractType[this.contractList[2].contractType.length - 1].editName = item.contractName;
                        this.contractList[2].contractType[this.contractList[2].contractType.length - 1].contractNum = item.contractNo;
                        this.contractList[2].contractType[this.contractList[2].contractType.length - 1].validityDate = item.contractBegin && item.contractEnd ? [`${item.contractBegin} 00:00:00`, `${item.contractEnd} 00:00:00`] : [];
                    } else this.contractList[2].contractType.push({
                        isChecked: true,
                        editName: item.contractName,
                        contractNum: item.contractNo,
                        validityDate: item.contractBegin && item.contractEnd ? [`${item.contractBegin} 00:00:00`, `${item.contractEnd} 00:00:00`] : []
                    })
                })
            }
            if (form.operatorOtherContract) {
                JSON.parse(form.operatorOtherContract).forEach((item, index) => {
                    if (index === 0) {
                        this.contractList[3].contractType[this.contractList[3].contractType.length - 1].isChecked = true;
                        this.contractList[3].contractType[this.contractList[3].contractType.length - 1].editName = item.contractName;
                        this.contractList[3].contractType[this.contractList[3].contractType.length - 1].contractNum = item.contractNo;
                        this.contractList[3].contractType[this.contractList[3].contractType.length - 1].validityDate = item.contractBegin && item.contractEnd ? [`${item.contractBegin} 00:00:00`, `${item.contractEnd} 00:00:00`] : [];
                    } else this.contractList[3].contractType.push({
                        isChecked: true,
                        editName: item.contractName,
                        contractNum: item.contractNo,
                        validityDate: item.contractBegin && item.contractEnd ? [`${item.contractBegin} 00:00:00`, `${item.contractEnd} 00:00:00`] : []
                    })
                })
            }
            if (contractInfo.firstMerchant) {
                contractInfo.firstMerchant.filter(({used}) => !used).forEach((item, index) => {
                    if (index === 0) {
                        this.contractList[0].contractType[this.contractList[0].contractType.length - 1].isChecked = true;
                        this.contractList[0].contractType[this.contractList[0].contractType.length - 1].editName = item.name;
                        this.contractList[0].contractType[this.contractList[0].contractType.length - 1].contractNum = item.contractNum;
                        this.contractList[0].contractType[this.contractList[0].contractType.length - 1].validityDate = item.effectiveDate && item.expirationDate ? [(item.effectiveDate + ' 00:00:00'), (item.expirationDate + ' 00:00:00')] : [];
                    } else this.contractList[0].contractType.push({
                        isChecked: true,
                        editName: item.name,
                        contractNum: item.contractNum,
                        validityDate: item.effectiveDate && item.expirationDate ? [`${item.effectiveDate} 00:00:00`, `${item.expirationDate} 00:00:00`] : []
                    })
                })
            }
            if (data.approvalStatus == 2) {
                form.editShow = false;
            } else {
                form.editShow = true;
            }
            return form;
        },
        editChange() {
            if (this.showButton) {
                this.getOrgEditInfo();
            } else {
                this.getOrgInfo();
            }
        },
        //增加或减少合同信息
        addContractType(type, index, actionType) {
            if (actionType)
                this.contractList.find((item) => item.type === type).contractType.push({
                    isChecked: true,
                    editName: "",
                    validityDate: [],
                    contractNum: ""
                })
            else
                this.contractList.find((item) => item.type === type).contractType.splice(index, 1)
        }
    },
    //监听模版变量
    watch: {}

}
</script>

